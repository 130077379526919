.watermarked-div {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  z-index: 100;

}

.watermarked-div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(150, 146, 146, 0.48); */
  pointer-events: none;
  overflow: hidden;
}

.watermarked-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.45;
  background-image: url('../../../assets/img/watermark_VAAZME_DATA_AUTHENTICATION.png');
  z-index: 100;
}
  .text-center {
    padding-top: 30px;
    text-align: left;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }
  
  th, td, tr {
    border: 1px solid grey !important;
    text-align: left;
  }
  .reportTable1 {
    display: block;
    visibility: visible;
    z-index: -999;
  }
  .reportTable2 {
    display: none;
    visibility: hidden;
    z-index: -999;
  }
  @media screen and (min-width: 850px) {
    .watermarked-div::before {
      background-size: contain !important;
    }
    
  .img-cert-overlap{
    height: 200px !important;
    width:  300px !important;
    padding-top: 10px !important;
    position: relative;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }
  .img-cert-rectangle {
    border-style: solid;
    height: 180px !important;
    width: 300px !important;
    border-radius: 5px !important;
    left: 0;
    position: absolute;
    top: 15px;
    width: 112px;
    border: 1px solid var(--bon-jour);
  }
  
  .img-cert-ic {
    height: 100% !important;
    width: 100% !important;
    object-fit: fill;
    position: none !important;
    margin: 0 !important;
    right: 0 !important;
    left: 0 !important;
    padding: 12px;
    
  }

  .cert-check{
    height: 23px;
    left: 285px !important;
    position: absolute;
    top: 5px;
    width: 23px;
    z-index: 1;
  }

  .img-ellipse-positive-cert {
    background-color: var(--skeptic);
    border-radius: 16px;
    height: 32px;
    left: 280px  !important;
    position: absolute;
    top: 0;
    width: 32px;
    z-index: 1;
}
.img-ellipse-negative-cert {
  background-color: var(--corporate-red);
  border-radius: 16px;
  height: 32px;
  left: 280px  !important;
  position: absolute;
  top: 0;
  width: 32px;
  z-index: 1;
}

.img-cert-selfie {
    height: 100% !important;
    padding-bottom: 25px !important;
    left: 4px;
    position: absolute;
    top: 17px;
    width: auto;
    margin: 0 auto;
    left: 4px;
    right: 20px;
    object-fit: cover;
}
.reportTable1 {
  display: none !important;
  visibility: hidden !important;  
}
.reportTable2 {
  display: block !important;
  visibility: visible !important;
}
}