body {
    background-color: #f4f4f4;
    min-height: 100%;
    max-width: 480px;
    margin: 0 auto;
}

@media (min-width: 736px) {
    .app-screen {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        width: calc(100% - 40px);
    }
}

.app-screen {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 auto 0;
    max-width: 935px;
    border: 1px solid #dadada;
    background-color: var(--white);
    min-height: 100vh;
}

.appbar-verified-icon {
    fill: green !important;
    font-size: 1.2rem !important;
}

.appbar-verified-icon-additional {
    margin-top: 0px;
    margin-bottom: 4px;
}