:root { 
    --alabaster: #f9f9fa;
    --alto: #dadada;
    --black: #000000;
    --black-2: #000000b2;
    --black-haze: #f7f7f7;
    --black-pearl: #02082aab;
    --bon-jour: #e0e0e0;
    --cinnabar: #e23e3e;
    --corporate-blue: #1d82b9;
    --corporate-green: #2fc08c;
    --corporate-red: #ef574a;
    --corporate-yellow: #fbb85d;
    --ebb: #e3e3e3;
    --gray: #7f7f7f;
    --gunsmoke: #858585;
    --loblolly: #c2c8d0;
    --mercury: #e7e7e7;
    --mercury-2: #e7e6e6;
    --mine-shaft: #303030;
    --mine-shaft-2: #313131;
    --mist-gray: #c4c4c4;
    --normal-brown: #353535;
    --normal-white: #ffffff;
    --outer-space: #2d333a;
    --periwinkle: #cdd0fb;
    --quill-gray: #d5d5d5;
    --red-orange: #ef574a1a;
    --seashell: #f1f1f1;
    --shady-lady: #aca5a5;
    --shady-lady-2: #a9a9a9;
    --skeptic: #ceecd7;
    --sunset-orange: #ff5757;
    --white: #ffffff80;

    --font-size-l: 14px;
    --font-size-m: 12px;
    --font-size-s: 11px;
    --font-size-xl: 16px;
    --font-size-xs: 10px;
    --font-size-xxl: 17px;
    --font-size-xxs: 8px;

    --font-family-montserrat: "Montserrat", Helvetica;
    --font-family-poppins: "Poppins", Helvetica;
    --font-family-roboto: "Roboto", Helvetica;
}
.montserrat-bold-white-12px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.montserrat-medium-black-16px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-gunsmoke-12px {
    color: var(--gunsmoke);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-tuatara-14px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-outer-space-16px {
    color: var(--outer-space);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-12px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-17px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-gray-17px {
    color: var(--gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-tuatara-12px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-pearl-11px {
    color: var(--black-pearl);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-gray-10px {
    color: var(--gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-14px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-11px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 500;
}

.montserrat-semi-bold-koromiko-15px {
    color: var(--corporate-yellow);
    font-family: var(--font-family-montserrat);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.montserrat-medium-black-18px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-white-17px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-white-12px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-9px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-bold-tuatara-14px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.montserrat-semi-bold-mine-shaft-12px {
    color: var(--mine-shaft);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 600;
}

.montserrat-medium-tuatara-11px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-tuatara-16px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-8px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: 500;
}

.montserrat-bold-white-14px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.montserrat-medium-black-13px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-black-36px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-shady-lady-9px {
    color: var(--shady-lady);
    font-family: var(--font-family-montserrat);
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-white-14px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.montserrat-light-eastern-blue-12px {
    color: var(--corporate-blue);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 300;
}

.montserrat-bold-red-orange-12px {
    color: var(--corporate-red);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.montserrat-bold-red-orange-14px {
    color: var(--corporate-red);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.montserrat-medium-black-22px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-white-16px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-bold-white-33px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
}

.montserrat-semi-bold-eastern-blue-15px {
    color: var(--corporate-blue);
    font-family: var(--font-family-montserrat);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.montserrat-semi-bold-mine-shaft-11px {
    color: var(--mine-shaft);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 600;
}

.roboto-normal-outer-space-16px {
    color: var(--outer-space);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.montserrat-semi-bold-mine-shaft-14px {
    color: var(--mine-shaft);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 600;
}

.montserrat-semi-bold-tuatara-16px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}

.montserrat-bold-tuatara-12px {
    color: var(--normal-brown);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.montserrat-bold-black-18px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.montserrat-medium-white-9px {
    color: var(--white);
    font-family: var(--font-family-montserrat);
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-mountain-meadow-14px {
    color: var(--corporate-green);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-sunset-orange-14px {
    color: var(--sunset-orange);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-sunset-orange-12px {
    color: var(--sunset-orange);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
}

.montserrat-semi-bold-white-12px {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 600;
}

.montserrat-semi-bold-red-orange-12px {
    color: var(--corporate-red);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 600;
}

.montserrat-bold-gray-10px {
    color: var(--gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 700;
}

.border-1px-loblolly {
    border: 1px solid var(--loblolly);
}

.border-1px-bon-jour {
    border: 1px solid var(--bon-jour);
}

.border-1px-alto {
    border: 1px solid var(--alto);
}

.border-1px-mercury {
    border: 1px solid var(--mercury);
}

.border-1px-periwinkle {
    border: 1px solid var(--periwinkle);
}

.border-1px-quill-gray {
    border: 1px solid var(--quill-gray);
}

.border-1px-black {
    border: 1px solid var(--black);
}

.border-1px-corporate-red {
    border: 1px solid var(--corporate-red);
}
  