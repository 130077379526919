.frame-8 {
    height: 253px;
    margin-left: 4.0px;
    margin-top: 69px;
    width: 318px;
    z-index: 4;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}

.otp-field {
    /* width: 38px;
    height: 40px; */
    text-align: center;
    /* box-sizing: content-box; */
    /* margin: 0 15px; */
    border-radius: 4px;
    height: 56px;
    width: 61px;
    border: 1px solid var(--loblolly);
}

/* .otp-field:nth-child(1) {
    margin-left: 0px;
} */

/* .otp-field:nth-child(4) {
    margin-right: 0px;
} */