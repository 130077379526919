.success-status-dot {
    background-color: var(--corporate-green);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
}

.success-status {
    color: var(--corporate-green);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.00px;
    line-height: 19.6px;
    text-align: right;
    white-space: nowrap;
}

.error-status-dot {
    background-color: var(--sunset-orange);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
}

.error-status {
    color: var(--sunset-orange);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.00px;
    line-height: 19.6px;
    text-align: right;
    white-space: nowrap;
}