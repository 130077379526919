.history-tab-font {
    height: 31px !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    min-height: 30px !important;
    color: var(--corporate-blue) !important;
    border-radius: 30px !important;
    min-width: 110px !important;
    text-transform: uppercase !important;
    border: 1px solid var(--mercury) !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.history-tab-font.Mui-selected {
    background-color: var(--corporate-blue) !important;
    color: var(--normal-white) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    border-radius: 30px !important;
    height: 31px !important;
    min-height: 30px !important;
    min-width: 110px !important;
    text-transform: uppercase !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    border: 1px solid var(--corporate-blue) !important;
}

.css-1aquho2-MuiTabs-indicator {
    height: 0px !important;
}

.all-set {
    color: #a9a9a9;
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.00px;
    line-height: normal;
}