.home-menu-box {
    background-color: var(--normal-white);
    border-radius: 10px;
    box-shadow: 0px 0px 20px #0000000d;
    cursor: pointer;
    height: auto;
    border: 1px solid var(--alto);
}

.home-menu-box-disable {
    background-color: lightgray;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #0000000d;
    cursor: pointer;
    height: auto;
    border: 1px solid var(--alto);
}

.card-img {
    height: 80px;
    width: 100%;
}

.summary-menu-box {
    background-color: var(--normal-white);
    border-radius: 15px;
    height: auto;
    /* border: 1px solid var(--alto); */
}

.warning-dot {
    background-color: var(--corporate-yellow);
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 8px;
    display: inline-block;
}

.img-overlap {
    height: 92px;
    position: relative;
    width: 128px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.img-overlap-wo-desc {
    position: relative;
    width: 128px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.img-rectangle {
    height: 77px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 112px;
    border: 1px solid var(--bon-jour);
}

.img-ellipse-positive {
    background-color: var(--skeptic);
    border-radius: 16px;
    height: 32px;
    left: 96px;
    position: absolute;
    top: 0;
    width: 32px;
    z-index: 1;
}

.img-ellipse-negative {
    background-color: var(--corporate-red);
    border-radius: 16px;
    height: 32px;
    left: 96px;
    position: absolute;
    top: 0;
    width: 32px;
    z-index: 1;
}

.check {
    height: 23px;
    left: 100px;
    position: absolute;
    top: 5px;
    width: 23px;
    z-index: 1;
}

.img-ic {
    height: 72px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 17px;
    width: auto;
    margin: 0 auto;
    right: 15px;
    padding: 5px;
}

.img-selfie {
    height: 71px;
    left: 4px;
    position: absolute;
    top: 17px;
    width: auto;
    margin: 0 auto;
    left: 4px;
    right: 20px;
    object-fit: cover;
}

.card-box-text {
    background-color: var(--corporate-red);
}

.card-box-text-clear {
    background-color: var(--corporate-green);
}

.card-img-wo-desc {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 200px;
}

.card-img-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0%;
}

@media only screen and (max-width: 600px) {
    .card-img-icon {
        right: 10%;
    }
}

.new-line {
    white-space: pre-line;
}


.card-rex-box {
    border: 1px solid red !important;
    padding: 3px;
}

.overFlowWrap {
    overflow-wrap: break-word;
}

.additionalText {
    color: var(--normal-white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;

    display: block;
    text-align: left;
    padding-left: 1em;
}

.additionalText ul {
    list-style: inside;
}

.additionalText li {
    text-indent: -1.5em;
    padding-left: 3em;
}
