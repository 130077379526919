.primary-button {
    background-image: linear-gradient(to left, #1D82B9, #767EFB);
    border-radius: 10px !important;
    padding: 10px !important;
    color: var(--normal-white) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
    margin-bottom: 5px !important;
}

.primary-button:disabled {
    background-image: linear-gradient(to left, #999a9c, #6e6c6c);
    cursor: not-allowed;
}

.close-button {
    background-color: red !important;
    border-radius: 10px !important;
    padding: 10px !important;
    color: var(--normal-white) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
}

.secondary-button {
    background-color: var(--normal-white) !important;
    border: 1px solid var(--normal-brown) !important;
    border-radius: 10px !important;
    padding: 10px !important;
    color: var(--normal-brown) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
}

.view-all-button {
    background-color: var(--normal-white) !important;
    border-radius: 5px !important;
    height: 41px !important;
    color: #6b6868 !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    border: 1px solid var(--alto) !important;
    letter-spacing: 0.00px;
    line-height: normal;
    box-shadow: 0 0 0 0 !important;
}

a {
    text-decoration: none !important;
}

.positive-button {
    align-items: flex-start;
    background-image: linear-gradient(to left, #1D82B9, #767EFB);
    border-radius: 4px;
    display: flex;
    height: 38px;
    min-width: 144px;
    padding: 11px 46px;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
}

.negative-button {
    background-color: var(--corporate-red) !important;
    border-radius: 10px !important;
    padding: 10px !important;
    color: var(--normal-white) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
    margin-bottom: 5px !important;
}

.negative-border-button {
    background-color: var(--normal-white) !important;
    border: 1px solid var(--corporate-red) !important;
    border-radius: 10px !important;
    color: var(--corporate-red) !important;
    min-width: 114px;
    padding: 10px !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
}

.negative-border-button:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important
}

.cutomized-button {
    border-radius: 10px !important;
    padding: 10px !important;
    /* color: var(--normal-white) !important; */
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
}

.raisedRounded {
    border-radius: 0px 10px 10px 0px !important;
    background-image: linear-gradient(to left, #1D82B9, #767EFB);
    padding: 10px !important;
    color: var(--normal-white) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    box-shadow: 0 0 0 0 !important;
}

.view-all-button svg {
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-m) !important;
    font-style: normal !important;
    font-weight: 900 !important;
    letter-spacing: 0.00px;
    line-height: normal;
}

.view-all-button span {
    position: absolute;
    right: 1rem;
}