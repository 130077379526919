.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    align-items: center;
    background-color: var(--normal-white);
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
    padding: 1px 0;
    width: 310px;
    text-align: center !important;
}

.customized-dialog {
    text-align: center !important;
}