@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Montserrat:700,300,500,600,500italic|Poppins:400|Roboto:400");

.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal>* {
    flex-shrink: 0;
    pointer-events: auto;
}

.align-self-flex-start {
    align-self: flex-start;
}

.align-self-flex-end {
    align-self: flex-end;
}

.valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

* {
    box-sizing: border-box;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-xl) !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: var(--outer-space) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: var(--font-size-xl) !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: var(--gunsmoke) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.7375em !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #1976d2 !important;
}

img {
    pointer-events: none;
}

.all-set {
    color: #a9a9a9;
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.00px;
    line-height: normal;
}